
import { defineComponent } from 'vue';
import { Icon } from '@iconify/vue';
export default defineComponent({
     name: 'ContentCard',
     props: {
          icon: {
               type: String,
               default: 'emojione-monotone:electric-plug',
          },
          iconFlip: {
               type: Boolean,
               default: false,
          },
          iconRotate: {
               type: Number,
               default: 0,
          },
          twoLineTitle: {
               type: Boolean,
               default: true,
          },
          coloredPart: {
               type: Boolean,
               default: false,
          },
          coloredTitlePart: {
               type: String,
               default: 'Ditt hus.',
          },
          title: {
               type: String,
               default: 'Ditt hus.',
          },
          titleLineTwo: {
               type: String,
               default: 'Din energi',
          },
          text: {
               type: String,
               default: '',
          },
          hasList:{
               type: Boolean,
               default: false,
          },
          hasContact:{
            type: Boolean,
            default: false
          },
          email:{
            type:String,
            default: '',
          },
          phone:{
            type:String,
            default: '',
          },
          items: Object,
          longParagraph: {
               type: Boolean,
               Default: false,
          },
          hasImage: {
               type: Boolean,
               Default: false,
          },
          hasButton: {
               type: Boolean,
               default: false,
          },
          buttonName: {
               type: String,
               default: 'Räkna på ditt tak',
          },
     },
     // data(){
     //      return{
     //           items: [
     //                {
     //                     id: 1,
     //                     text: "dasfdfh"
     //                }
     //           ]
     //      }
     // },
     components: { Icon },
     methods: {
          buttonEvent() {
               this.$emit('cardEvent');
          },
     },
});
