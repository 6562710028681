
import { defineComponent } from "vue";
import { Icon } from "@iconify/vue";
export default defineComponent({
  name: "MenuComponent",
  components: { Icon },
  methods: {
    close() {
      this.$emit("close");
    },
    scrollToTop() {
      this.$emit("scrollToTop");
    },
  },
});
