import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-80824fc0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "title" }
const _hoisted_2 = {
  key: 0,
  class: "colored-title-span"
}
const _hoisted_3 = { class: "title" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = { class: "image-container" }
const _hoisted_6 = { class: "image-box" }
const _hoisted_7 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.content.icon)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["icon-background", { 'icon-background': true, alternate: !_ctx.content.icon }])
        }, [
          _createVNode(_component_Icon, {
            class: "icon",
            icon: _ctx.content.icon,
            rotate: _ctx.content.props.iconRotate,
            horizontalFlip: _ctx.content.props.isHorizontalFlip,
            color: 'white'
          }, null, 8, ["icon", "rotate", "horizontalFlip"])
        ], 2))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(["title-wrapper", _ctx.content.icon ? '' : 'no-icon'])
    }, [
      _createElementVNode("h1", _hoisted_1, _toDisplayString(_ctx.content.title), 1),
      (_ctx.content.props.hasTwoLineTitle)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.content.props.optionalValues?.secondTitleLine), 1)
          ]))
        : _createCommentVNode("", true)
    ], 2),
    _createElementVNode("div", {
      class: "body-text",
      innerHTML: _ctx.domPurify.sanitize(_ctx.content.bodyText)
    }, null, 8, _hoisted_4),
    _createElementVNode("div", _hoisted_5, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.content.props.images, (image) => {
        return (_openBlock(), _createElementBlock("div", {
          key: image.title,
          class: "image-render"
        }, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("img", {
              src: image.url,
              alt: ""
            }, null, 8, _hoisted_7)
          ]),
          _createElementVNode("p", null, _toDisplayString(image.title), 1)
        ]))
      }), 128))
    ])
  ], 64))
}