
import { defineComponent } from 'vue';
import { contentfulImagePost } from '@/types/contentfulTypes';
import { Icon } from '@iconify/vue';
import DOMPurify from 'dompurify';
export default defineComponent({
  name: 'ImagePostComponent',
  components: { Icon },
  props: {
    content: {
      type: Object as () => contentfulImagePost,
      default: () => {},
    },
    isAlternate: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const domPurify = DOMPurify();

    return { domPurify };
  },
});
