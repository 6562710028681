import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-00fed7c8"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "icon-background"
}
const _hoisted_2 = {
  key: 0,
  class: "colored-title-span"
}
const _hoisted_3 = {
  key: 0,
  class: "title colored-title"
}
const _hoisted_4 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.content.icon)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_Icon, {
            class: "icon",
            icon: _ctx.content.icon,
            rotate: _ctx.content.props.iconRotate,
            horizontalFlip: _ctx.content.props.isHorizontalFlip,
            color: 'white'
          }, null, 8, ["icon", "rotate", "horizontalFlip"])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(["title-wrapper", _ctx.content.icon ? '' : 'no-icon'])
    }, [
      _createElementVNode("h1", {
        class: _normalizeClass(["title", _ctx.isAlternate ? 'alternate' : ''])
      }, _toDisplayString(_ctx.content.title), 3),
      (
        _ctx.content.props?.hasColoredPartTitle || _ctx.content.props?.hasTwoLineTitle
      )
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("h1", {
              class: _normalizeClass(["title", _ctx.isAlternate ? 'alternate' : ''])
            }, _toDisplayString(_ctx.content.props.optionalValues?.secondTitleLine), 3),
            (_ctx.content.props.optionalValues?.coloredTitle !== undefined)
              ? (_openBlock(), _createElementBlock("h1", _hoisted_3, _toDisplayString(_ctx.content.props.optionalValues?.coloredTitle), 1))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(["body-text", _ctx.isAlternate ? 'alternate' : '']),
      innerHTML: _ctx.domPurify.sanitize(_ctx.content.bodyText)
    }, null, 10, _hoisted_4)
  ], 64))
}