
import { defineComponent } from 'vue';
import ContentCardsContainer from '@/components/ContentCardsContainer.vue';
import { Icon } from '@iconify/vue';
import MenuComponent from '@/components/MenuComponent.vue';
import gsap from 'gsap';
import { getContentfulPost } from '@/service/contentfulService';
import AlternateCardContainer from '@/components/AlternateCardContainer.vue';

export default defineComponent({
  name: 'HomeView',
  components: {
    ContentCardsContainer,
    MenuComponent,
    Icon,
    AlternateCardContainer,
  },
  data() {
    return {
      menu: false,
    };
  },

  methods: {
    toggleMenu() {
      const isToggle = !this.menu;

      this.menu = isToggle;

      //  const tl = gsap.timeline();

      //  tl.to('.open-menu', {
      //       opacity: 0,
      //       duration: 0.5,
      //  });

      if (this.menu) {
        // tl.from('.menu-box', {
        //      opacity: 0,
        //      duration: 1,
        // });
        // tl.to('.menu-box', {
        //      left: -500,
        //      opacity: 1,
        //      duration: 2,
        // });
      }
    },
    closeMenu() {
      this.menu = false;
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    scrollToBottom() {
      window.scrollTo(0, 9999);
    },
  },
});
