import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9d051520"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card-container-box" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ContentCard = _resolveComponent("ContentCard")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.contents, (content, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: index,
        class: _normalizeClass({
        'card-container': true,
        alternate: index % 2 === 1,
      })
      }, [
        _createVNode(_component_ContentCard, {
          class: "card1",
          iconFlip: content.props.isIconFlip,
          iconRotate: content.props.optionalValues?.iconRotate,
          coloredPart: content.props.hasColoredPartTitle,
          twoLineTitle: content.props.hasTwoLineTitle,
          icon: content.icon,
          title: content.title,
          titleLineTwo: content.props.optionalValues?.secondTitleLine,
          coloredTitlePart: content.props.optionalValues?.coloredTitle,
          text: content.bodyText
        }, null, 8, ["iconFlip", "iconRotate", "coloredPart", "twoLineTitle", "icon", "title", "titleLineTwo", "coloredTitlePart", "text"])
      ], 2))
    }), 128))
  ]))
}