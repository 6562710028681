import {
  contentfulButtonPost,
  contentfulCardsPost,
  contentfulContactPost,
  contentfulImagePost,
  contentfulListAndButtonPost,
  contentfulTextContent,
} from '@/types/contentfulTypes';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { createClient, Entry, RichTextContent } from 'contentful';

export enum ContentType {
  CONTENT_POST = 0,
  LIST_BUTTON_POST = 1,
  BUTTON_POST = 2,
  IMAGE_POST = 3,
  CONTACT_POST = 4,
}

const client = createClient({
  space: process.env.VUE_APP_CONTENTFUL_SPACE,
  accessToken: process.env.VUE_APP_CONTENTFUL_TOKEN,
});

export const getContentfulPost = async (
  responseItem: Entry<any>
): Promise<contentfulCardsPost> => {
  // const response: { items: Entry<any>[] } = await client.getEntries({
  //   content_type: 'contentSection',
  //   order: 'sys.createdAt',
  // });

  const content: contentfulCardsPost = {
    title: responseItem.fields.title,
    bodyText: responseItem.fields.body,
    icon: responseItem.fields.icon,
    props: {
      isHorizontalFlip: responseItem.fields.isIconFlip,
      iconRotate: responseItem.fields.iconRotate,
      hasTwoLineTitle: responseItem.fields.hasTwoLineTitle,
      hasColoredPartTitle: responseItem.fields.hasColoredPartTitle,
      optionalValues: {
        secondTitleLine: responseItem.fields.secondTitleLine,
        coloredTitle: responseItem.fields.coloredTitle,
      },
    },
  };

  return content;
};

export const getContentfulListButtonPost = async (
  responseItem: Entry<any>
): Promise<contentfulListAndButtonPost> => {
  // const response: { items: Entry<any>[] } = await client.getEntries({
  //   content_type: 'listAndButtonPost',
  //   order: 'sys.createdAt',
  // });

  const content: contentfulListAndButtonPost = {
    title: responseItem.fields.title,
    bodyText: responseItem.fields.body,
    icon: responseItem.fields.icon,
    props: {
      isHorizontalFlip: responseItem.fields.isHorizontalFlip,
      iconRotate: responseItem.fields.rotateIcon,
      hasTwoLineTitle: responseItem.fields.hasTwoLineTitle,
      itemsList: responseItem.fields.itemsList,
      buttonName: responseItem.fields.buttonName,
      buttonLink: responseItem.fields.buttonLink,
      optionalValues: {
        secondTitleLine: responseItem.fields.secondTitleLine,
      },
    },
  };

  return content;
};

export const getContentfulButtonPost = async (
  responseItem: Entry<any>
): Promise<contentfulButtonPost> => {
  // const response: { items: Entry<any>[] } = await client.getEntries({
  //   content_type: 'postWithButton',
  //   order: 'sys.createdAt',
  // });

  const content: contentfulButtonPost = {
    title: responseItem.fields.title,
    bodyText: responseItem.fields.body,
    icon: responseItem.fields.icon,
    props: {
      isHorizontalFlip: responseItem.fields.isHorizontalFlip,
      iconRotate: responseItem.fields.rotateIcon,
      hasTwoLineTitle: responseItem.fields.hasTwoLineTitle,
      buttonName: responseItem.fields.buttonName,
      buttonLink: responseItem.fields.buttonLink,
      optionalValues: {
        secondTitleLine: responseItem.fields.secondTitleLine,
      },
    },
  };

  return content;
};

export const getContentfulImagePost = async (
  responseItem: Entry<any>
): Promise<contentfulImagePost> => {
  // const response: { items: Entry<any>[] } = await client.getEntries({
  //   content_type: 'about',
  //   order: 'sys.createdAt',
  // });

  const content: contentfulImagePost = {
    title: responseItem.fields.title,
    bodyText: responseItem.fields.body,
    icon: responseItem.fields.icon,
    props: {
      isHorizontalFlip: responseItem.fields.isHorizontalFlip,
      iconRotate: responseItem.fields.rotateIcon,
      hasTwoLineTitle: responseItem.fields.hasTwoLineTitle,
      images: responseItem.fields.images.map((x: any) => {
        return { title: x.fields.title, url: x.fields.file.url };
      }),
      optionalValues: {
        secondTitleLine: responseItem.fields.secondTitleLine,
      },
    },
  };

  return content;
};

export const getContentfulContactPost = async (
  responseItem: Entry<any>
): Promise<contentfulContactPost> => {
  const response: { items: Entry<any>[] } = await client.getEntries({
    content_type: 'contact',
    order: 'sys.createdAt',
  });

  const content: contentfulContactPost = {
    title: responseItem.fields.title,
    bodyText: responseItem.fields.body,
    icon: responseItem.fields.icon,
    props: {
      isHorizontalFlip: responseItem.fields.isHorizontalFlip,
      iconRotate: responseItem.fields.rotateIcon,
      hasTwoLineTitle: responseItem.fields.hasTwoLineTitle,
      emailIcon: responseItem.fields.emailIcon,
      email: responseItem.fields.contactEmail,
      phoneIcon: responseItem.fields.phoneIcon,
      phone: responseItem.fields.contactPhone,
      optionalValues: {
        secondTitleLine: responseItem.fields.secondTitleLine,
      },
    },
  };

  return content;
};

export const getContentful = async (): Promise<any[]> => {
  const response: { items: Entry<any>[] } = await client.getEntries({
    content_type: 'contentOrder',
    order: 'sys.createdAt',
  });

  const itemContentType = response.items[0].fields.order.filter((x: any) =>
    Object.values(ContentType).includes(x.fields.contentType)
  );

  const content = await Promise.all(
    itemContentType.map(async (item: any) => {
      switch (item.fields.contentType) {
        case ContentType.CONTENT_POST:
          return {
            type: ContentType.CONTENT_POST,
            data: await getContentfulPost(item),
          };

        case ContentType.LIST_BUTTON_POST:
          return {
            type: ContentType.LIST_BUTTON_POST,
            data: await getContentfulListButtonPost(item),
          };

        case ContentType.BUTTON_POST:
          return {
            type: ContentType.BUTTON_POST,
            data: await getContentfulButtonPost(item),
          };
        case ContentType.IMAGE_POST:
          return {
            type: ContentType.IMAGE_POST,
            data: await getContentfulImagePost(item),
          };
        case ContentType.CONTACT_POST:
          return {
            type: ContentType.CONTACT_POST,
            data: await getContentfulContactPost(item),
          };
        default:
          break;
      }
    })
  );

  return content;
};

// export const getRichText = async () => {
//   const content = await getContent();
//   const richText = content.map((item) => {
//     return item.body;
//   });
//   return richText;
// };
