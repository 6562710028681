import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-723ea07c"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "colored-title-span"
}
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = { class: "button-container" }
const _hoisted_4 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.content.icon)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["icon-background", { 'icon-background': true, alternate: !_ctx.content.icon }])
        }, [
          _createVNode(_component_Icon, {
            class: "icon",
            icon: _ctx.content.icon,
            rotate: _ctx.content.props.iconRotate,
            horizontalFlip: _ctx.content.props.isHorizontalFlip,
            color: 'white'
          }, null, 8, ["icon", "rotate", "horizontalFlip"])
        ], 2))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(["title-wrapper", _ctx.content.icon ? '' : 'no-icon'])
    }, [
      _createElementVNode("h1", {
        class: _normalizeClass(["title", _ctx.isAlternate ? 'alternate' : ''])
      }, _toDisplayString(_ctx.content.title), 3),
      (_ctx.content.props.hasTwoLineTitle)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("h1", {
              class: _normalizeClass(["title", _ctx.isAlternate ? 'alternate' : ''])
            }, _toDisplayString(_ctx.content.props.optionalValues?.secondTitleLine), 3)
          ]))
        : _createCommentVNode("", true)
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(["body-text", _ctx.isAlternate ? 'alternate' : '']),
      innerHTML: _ctx.domPurify.sanitize(_ctx.content.bodyText)
    }, null, 10, _hoisted_2),
    _createElementVNode("div", {
      class: _normalizeClass(["list-container", _ctx.isAlternate ? 'alternate' : ''])
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.content.props.itemsList, (item) => {
        return (_openBlock(), _createElementBlock("ul", null, [
          _createElementVNode("li", null, _toDisplayString(item), 1)
        ]))
      }), 256))
    ], 2),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("a", {
        href: _ctx.content.props.buttonLink,
        target: "_blank"
      }, [
        _createElementVNode("button", null, _toDisplayString(_ctx.content.props.buttonName), 1)
      ], 8, _hoisted_4)
    ])
  ], 64))
}