import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-fd521ade"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"color":"red"} }
const _hoisted_2 = { style: {"color":"red"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.contents, (content) => {
    return (_openBlock(), _createElementBlock("div", null, [
      _createVNode(_component_Icon, {
        icon: content.icon,
        style: {"color":"red"}
      }, null, 8, ["icon"]),
      _createElementVNode("h1", _hoisted_1, _toDisplayString(content.title), 1),
      _createElementVNode("p", _hoisted_2, _toDisplayString(content.bodyText), 1)
    ]))
  }), 256))
}