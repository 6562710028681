import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4876d4be"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "colored-title-span"
}
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = { class: "contact-container" }
const _hoisted_4 = { class: "contact-email d-flex-justify-center" }
const _hoisted_5 = ["href"]
const _hoisted_6 = { class: "contact-phone d-flex-justify-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.content.icon)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["icon-background", { 'icon-background': true, alternate: !_ctx.content.icon }])
        }, [
          _createVNode(_component_Icon, {
            class: "icon",
            icon: _ctx.content.icon,
            rotate: _ctx.content.props.iconRotate,
            horizontalFlip: _ctx.content.props.isHorizontalFlip,
            color: 'white'
          }, null, 8, ["icon", "rotate", "horizontalFlip"])
        ], 2))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(["title-wrapper", _ctx.content.icon ? '' : 'no-icon'])
    }, [
      _createElementVNode("h1", {
        class: _normalizeClass(["title", _ctx.isAlternate ? 'alternate' : ''])
      }, _toDisplayString(_ctx.content.title), 3),
      (_ctx.content.props.hasTwoLineTitle)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("h1", {
              class: _normalizeClass(["title", _ctx.isAlternate ? 'alternate' : ''])
            }, _toDisplayString(_ctx.content.props.optionalValues?.secondTitleLine), 3)
          ]))
        : _createCommentVNode("", true)
    ], 2),
    (_ctx.content.bodyText)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass(["body-text", _ctx.isAlternate ? 'alternate' : '']),
          innerHTML: _ctx.domPurify.sanitize(_ctx.content.bodyText)
        }, null, 10, _hoisted_2))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_Icon, {
          class: _normalizeClass(["small-icon-size", _ctx.isAlternate ? 'alternate' : '']),
          icon: _ctx.content.props.emailIcon
        }, null, 8, ["class", "icon"]),
        _createElementVNode("a", {
          class: _normalizeClass(_ctx.isAlternate ? 'alternate' : ''),
          href: `mailto:${_ctx.content.props.email}`
        }, _toDisplayString(_ctx.content.props.email), 11, _hoisted_5)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_Icon, {
          class: _normalizeClass(["small-icon-size", _ctx.isAlternate ? 'alternate' : '']),
          icon: _ctx.content.props.phoneIcon
        }, null, 8, ["class", "icon"]),
        _createElementVNode("p", {
          class: _normalizeClass(_ctx.isAlternate ? 'alternate' : '')
        }, _toDisplayString(_ctx.content.props.phone), 3)
      ])
    ])
  ], 64))
}