import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-50e624a1"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "component-grid" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.contents, (content, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: index,
        class: "component-wrapper"
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.getClass(index, content.type, content.hasIcon))
        }, [
          (_openBlock(), _createBlock(_resolveDynamicComponent(content.type), {
            content: content.data,
            "is-alternate": index % 2 === 1 ? true : false
          }, null, 8, ["content", "is-alternate"]))
        ], 2)
      ]))
    }), 128))
  ]))
}