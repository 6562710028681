
import { defineComponent } from 'vue';
import ContentCard from '@/components/ContentCard.vue';
import { getContentfulPost } from '@/service/contentfulService';
import { contentfulCardsPost } from '@/types/contentfulTypes';
export default defineComponent({
  name: 'ContentCardsContainer',
  components: {
    ContentCard,
  },
  data() {
    return {
      contents: [] as contentfulCardsPost[],
      longParagraph: false,
      items: [
        {
          id: 1,
          text: 'Projektering och dimensionering av anläggning, levererad till uppdragsadressen.',
        },
        {
          id: 2,
          text: 'Komplett installation av beställd vara/tjänst, eventuella ställningar & montage.',
        },
        {
          id: 3,
          text: 'För- och Efteranmälan till nätägaren samt uppkoppling av växelriktare till internet för övervakning av produktione-förutsatt att internetanslutning finns tillgänglig.',
        },
        {
          id: 4,
          text: 'Egenkontroll utförs av behörig personal.',
        },
        {
          id: 5,
          text: 'Arbetsmiljöverkets föreskrifter följs.',
        },
      ],
    };
  },

  // async mounted() {
  //   await this.assignContents();
  // },

  methods: {
    redirect(path: string) {
      switch (path) {
        case 'portal':
          window.open(
            'https://portal.zenitec.se/kalkyl/194abdf1-ff79-4647-a3ba-cd75aef077a5?fbclid=IwAR1_KJgG1PE2yD4Ka80bRwGnM3NYrxyQmwLLE2S9IE3xvycbxF2oEim92lw',
            '_blank'
          );
          break;

        case 'readGreen':
          window.open(
            'https://www.skatteverket.se/privat/fastigheterochbostad/gronteknik.4.676f4884175c97df4192860.html',
            '_blank'
          );
          break;

        default:
          break;
      }
    },
    returnString(text: number) {
      switch (text) {
        case 1:
          const string1 =
            'D&C Sweden satsar på solceller med en lång livslängd där upp till 95 procent kan återvinnas. Likaså erbjuder vi batterier med lång livslängd där företag investerar i återvinning. Genom att minska antalet led i leveranskedjan så arbetar vi för att hålla nere priserna.';

          return string1;
        case 2:
          const string2 =
            'Vi tar fram en kostnadsfri offert och rådgivning kring den bästa lösningen som matchar dina behov och önskemål. I vårt pris ingår: ';

          return string2;
        case 3:
          const string3 =
            'Vi vill med våra solceller se till att skona de svenska hushållen från skyhöga elpriser, genom att hushållen har en egen förnybar elproduktion. Detta för att exempelvis kunna lagra el för att undvika beroende av elnätet när priset är högt. D&C Sweden erbjuder därför en komplett lösning med solceller och batterilagring till hushåll och företag.';
          return string3;
        case 4:
          const string4 = '';
          return string4;

        default:
          break;
      }
    },
    // async assignContents() {
    //   this.contents = await getContentfulPost();
    // },
  },
});
