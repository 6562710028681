
import { getContentfulPost } from '@/service/contentfulService';
import { contentfulTextContent } from '@/types/contentfulTypes';
import { Icon } from '@iconify/vue';
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'TestView',
  components: { Icon },

  data() {
    return {
      contents: [] as contentfulTextContent[],
      icon: '',
      title: '',
      body: '',
    };
  },

  // async mounted() {
  //   await this.assignContent();
  // },

  methods: {
    // async assignContent() {
    //   this.contents = await getContentfulPost();
    // },
  },
});
