
import { defineComponent } from 'vue';
import { getContentful } from '@/service/contentfulService';
import { Icon } from '@iconify/vue';
import DOMPurify from 'dompurify';
import ListAndButtonComponent from './contentful/ListAndButtonComponent.vue';
import PostComponent from './contentful/PostComponent.vue';
import ButtonPostComponent from './contentful/ButtonPostComponent.vue';
import ImagePostComponent from './contentful/ImagePostComponent.vue';
import ContactPostComponent from './contentful/ContactPostComponent.vue';
import { ContentType } from '@/service/contentfulService';

export default defineComponent({
  name: 'AlternateCardContainer',
  components: {
    Icon,
    PostComponent,
    ListAndButtonComponent,
    ButtonPostComponent,
    ImagePostComponent,
    ContactPostComponent,
  },

  data() {
    return {
      contents: [] as any[],
      DOMPurify,
    };
  },
  async mounted() {
    const content = await getContentful();

    this.contents = content.map((item) => {
      const hasIcon = item.data.icon ? true : false;

      switch (item.type) {
        case ContentType.CONTENT_POST:
          return { type: 'PostComponent', data: item.data, hasIcon };
        case ContentType.LIST_BUTTON_POST:
          return { type: 'ListAndButtonComponent', data: item.data, hasIcon };
        case ContentType.BUTTON_POST:
          return { type: 'ButtonPostComponent', data: item.data, hasIcon };
        case ContentType.IMAGE_POST:
          return { type: 'ImagePostComponent', data: item.data, hasIcon };
        case ContentType.CONTACT_POST:
          return { type: 'ContactPostComponent', data: item.data, hasIcon };
        default:
          break;
      }
    });
  },
  methods: {
    getClass(index: number, type: string, hasIcon: boolean) {
      const isAlternate = index % 2 === 1;


      switch (type) {
        case 'PostComponent':
          return `content-card post-component ${
            isAlternate ? 'alternate' : ''
          } ${hasIcon ? '' : 'no-icon'} `;
        case 'ListAndButtonComponent':
          return `content-card list-button-component ${
            isAlternate ? 'alternate' : ''
          } ${hasIcon ? '' : 'no-icon'}`;
        case 'ButtonPostComponent':
          return `content-card button-component ${
            isAlternate ? 'alternate' : ''
          } ${hasIcon ? '' : 'no-icon'}`;
        case 'ImagePostComponent':
          return `content-card image-component ${
            isAlternate ? 'alternate' : ''
          } ${hasIcon ? '' : 'no-icon'} `;
        case 'ContactPostComponent':
          return `content-card contact-component ${
            isAlternate ? 'alternate' : ''
          } ${hasIcon ? '' : 'no-icon'} `;

        default:
          break;
      }
    },
  },
});
